import React, { useEffect, useState, useCallback } from 'react'
import { useEmblaCarousel } from 'embla-carousel/react'
import Img from 'gatsby-image'

const viewportCss = {
    //overflow:'hidden'
}
const containerCss = {
      display: 'flex',
}
const slideCss = {
      height:'614px',
}

const Carousel = ({images}) => {
    const [emblaRef, embla] = useEmblaCarousel(
    { 
        loop: false,
    })

    const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

    const nextSlide = useCallback(() => embla.scrollNext(), [embla]);
    const prevSlide = useCallback(() => embla.scrollPrev(), [embla]);
    
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevButtonEnabled(embla.canScrollPrev());
        setNextButtonEnabled(embla.canScrollNext());
    }, [embla]);

    useEffect(() => {
        if (!embla) return;
        embla.on("select", onSelect);
        onSelect();
    }, [embla, onSelect])

    return (
        
        <div className="relative">
            <NextButton onClick={nextSlide} enabled={nextButtonEnabled}/>
            <PrevButton onClick={prevSlide} enabled={prevButtonEnabled}/>
            <div className="relative" style={viewportCss} ref={emblaRef}>
                <div className="" style={containerCss}>
                    {images.map(image => {
                        return <CarouselImage key={image.id} image={image} />
                    })
                    }
        
                </div>
            </div>
        </div>
    )
}

const NextButton = ({onClick, enabled}) => {
    return enabled && ( 
    <button className="next-slide outline-none absolute z-50 border-shadow" onClick={onClick}>
        <svg className="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
    </button>
    )
}

const PrevButton = ({onClick, enabled}) => {
     
    return enabled && ( 
    <button className="prev-slide outline-none absolute z-50 border-shadow" onClick={onClick}>
        <svg className="w-6 h-6 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
    </button>
    )
}


const CarouselImage = ({image}) => {

    console.log(image);

    return (
        <div className="min-w-full px-2 sm:px-4 carousel-slide">
            {image.fluid.aspectRatio < 1 ? 
            <Img imgStyle={{objectFit:"contain"}} className="opacity-50 object-cover object-center h-full" alt="promo image" fluid={image.fluid} loading="eager" />
            :
            <Img imgStyle={{objectFit:"cover"}} className="opacity-50 object-cover object-center h-full" alt="promo image" fluid={image.fluid} loading="eager" />
            }
        </div>
    )
}

export default Carousel
