import React from "react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import Layout from "../layouts/layout"
import Carousel from "../components/carousel"

const Project = ({ data: { hero, project } }) => {
    return (
        <Layout hero={{ smallerTextHeading: true, ...hero }}>
            <div className="container mx-auto mb-16 px-8">
                <div className="sm:flex flex-wrap">
                    <div className="sm:w-6/12 pr-4 mb-8">
                        {project.innerDescrption &&
                            project.innerDescrption.childMarkdownRemark && (
                                <div
                                    className="mb-8"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            project.innerDescrption
                                                .childMarkdownRemark.html,
                                    }}
                                />
                            )}

                        {project.location && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">Location</p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.location}</p>
                                </div>
                            </div>
                        )}

                        {project.client && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">Client</p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.client}</p>
                                </div>
                            </div>
                        )}

                        {project.ownerRepresentative && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">
                                        Owner Representative
                                    </p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.ownerRepresentative}</p>
                                </div>
                            </div>
                        )}

                        {project.consultant && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">Consultant</p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.consultant}</p>
                                </div>
                            </div>
                        )}

                        {project.designer && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">Designer</p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.designer}</p>
                                </div>
                            </div>
                        )}

                        {project.engineer && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">Engineer</p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.engineer}</p>
                                </div>
                            </div>
                        )}

                        {project.builtUpArea && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">Built Up Area</p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.builtUpArea}</p>
                                </div>
                            </div>
                        )}

                        {project.startDate && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">Start Date</p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.startDate}</p>
                                </div>
                            </div>
                        )}

                        {project.projectDuration && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">
                                        Project Duration
                                    </p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.projectDuration}</p>
                                </div>
                            </div>
                        )}

                        {project.partners && (
                            <div className="sm:flex mb-4 pb-6 border-b">
                                <div className="flex-1">
                                    <p className="font-bold">
                                        Partners
                                    </p>
                                </div>
                                <div className="flex-1">
                                    <p> {project.partners}</p>
                                </div>
                            </div>
                        )}

                    </div>

                    <div className="hidden sm:block sm:w-5/12 ml-auto">
                        {project.picture && 
                        <Img
                            className=""
                            alt=""
                            fluid={project.picture.fluid}
                        />
                        }
                    </div>
                </div>
            </div>

            {project.carouselImages && (
                <div className="bg-gray border-t border-b py-8 sm:py-16 mb-16">
                    <div className="container mx-auto project-carousel">
                        <Carousel images={project.carouselImages} />
                    </div>
                </div>
            )}

            {project.relatedProjects && (
                <div className="container mx-auto mb-16">
                    <p className="font-bold font-aktiv text-2xl px-4">
                        Similar Projects
                    </p>
                    <div className="sm:flex flex-wrap justify-between our-work-projects">
                        {project.relatedProjects.map(node => {
                            return (
                                <div
                                    className="project-card p-4 mx-auto relative w-full sm:w-1/2 lg:w-1/3 xl:w-1/4"
                                    key={node.slug}
                                >
                                    <div
                                        className="project-card mb-24"
                                        style={{ height: "331px" }}
                                    >
                                        <Link
                                            to={
                                                node.slug &&
                                                `/our-work/${node.slug}`
                                            }
                                        >
                                            {node.picture && 
                                            <Img
                                                className="object-cover object-center w-full h-full block"
                                                fluid={node.picture.fluid}
                                            />
                                            }
                                            <div className="p-4 border-shadow">
                                                <h3 className="text-gray-800 font-aktiv font-bold">
                                                    {node.name}
                                                </h3>
                                                {node.location && (
                                                    <h4 className="text-sm text-gray-800">
                                                        {node.location}
                                                    </h4>
                                                )}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </Layout>
    )
}

export const query = graphql`
    query ProjectQuery($slug: String!) {
        hero: contentfulProjects(slug: { eq: $slug }) {
            heroBackgroundImage {
                resize(width: 2000, quality: 60) {
                    src
                }
            }
            heroBackgroundImageFallback: picture {
                resize(width: 2000, quality: 60) {
                    src
                }
            }
            heroText: description {
                childMarkdownRemark {
                    html
                }
            }
            heroHeading: name
        }
        project: contentfulProjects(slug: { eq: $slug }) {
            innerDescrption {
                childMarkdownRemark {
                    html
                }
            }
            picture {
                fluid(maxHeight: 830, quality: 60) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            location
            client
            ownerRepresentative
            consultant
            designer
            engineer
            builtUpArea
            partners
            startDate(formatString: "MMMM YYYY")
            projectDuration
            carouselImages {
                id
                fluid(maxWidth: 1100, quality: 60) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                    aspectRatio
                }
            }
            relatedProjects {
                slug
                name
                location
                picture {
                    fluid(maxWidth: 600, quality: 60) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
        }
        projects: allContentfulProjects(limit: 4) {
            edges {
                node {
                    id
                    name
                    description {
                        childMarkdownRemark {
                            excerpt
                        }
                    }
                    location
                    picture {
                        title
                        fluid(maxHeight: 265, quality: 60) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
    }
`

export default Project
